import React from "react";

function Checkout() {
  return (
    <div className="class text-center m-5">
      <h3>Your order has been place</h3>
      <h5>Thank you for shopping with us</h5>
    </div>
  );
}

export default Checkout;
